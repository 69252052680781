<template>
    <router-view/>
</template>

<script>
export default {
  name: 'App',
   metaInfo: {
      title: 'BOOM Enter-Training: Worldwide training for everyone',
      description:'We help you discover your inner potencial so that you live according to your calling, impacting your family, your work, the world, galaxies and beyond!',
      meta: [
   
                {property: 'og:image', content: '/img/logo-blanco.svg' }    
            ]
    }
}
</script>

<style>
@import "./styles/main.css";

</style>
