import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("@/views/Home.vue");
const Workshop = () => import("@/views/Workshop.vue");
const AboutUs = () => import("@/views/AboutUs.vue");
const FindPowers = () =>import("@/views/FindPowers.vue");
const MadeToSize = () =>import("@/views/MadeToSize.vue");
const BecomeAmbassador = () =>import("@/views/BecomeAmbassador.vue");
const ActivateYourPowers = () =>import("@/views/ActivateYourPowers.vue");
const BoomTools = () =>import("@/views/BoomTools.vue");
Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path:"/workshop/:id",
    name: "workshop",
    component: Workshop,
  },

  {
    path:"/about-boom",
    name: "about-us",
    component: AboutUs,
  },
  {
    path:"/workshops",
    name: "find-powers",
    component: FindPowers,
  },
  {
    path: "/made-to-size",
    name: "made-to-size",
    component: MadeToSize
  },
  {
    path: "/activate-powers",
    name: "become-ambassador",
    component: BecomeAmbassador
  },
  {
      path: "/activate-your-powers",
      name: "activate-your-powers",
      component: ActivateYourPowers
    
  },
  {
    path:"/boom-tools",
    name: "boom-tools",
    component: BoomTools,
  }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/',
  
});

export default router;