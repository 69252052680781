import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import Autocomplete from 'v-autocomplete'
import VueIframe from 'vue-iframes'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMeta from 'vue-meta';


Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Autocomplete);
Vue.use(VueIframe);
Vue.use(VueMeta);

new Vue({
  render: h => h(App),
  router,
  components: { App }
}).$mount('#app')
